<template>
  <div class="career-vacancy__filter-item">
    <div class="career-vacancy__title">{{ $t(title) }}</div>
    <div class="career-vacancy__filter-selected-wrapper">
      <div class="career-vacancy__filter-selected" @click="toggleSelect">
        {{ selectedItem ? $t(selectedItem.attributes[field]) : $t(subtitle) }}
      </div>
    </div>
    <div class="career-vacancy__filter-dropdown is-active" v-if="isShowSelect">
      <div v-if="items" class="career-vacancy__filter-list">
        <div
          v-for="item in items"
          :key="item.id"
          class="career-vacancy__filter-select"
          :class="{
            active: item === selectedItem,
          }"
          @click="selectItem(item)"
        >
          {{ getFilterSelect(item) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { actionTypes } from "@/store/modules/vacancy";
export default {
  name: "VacancySelect",
  props: {
    title: {
      type: String,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isShowSelect: false,
      selectedItem: null,
    };
  },
  computed: {
    ...mapState("vacancyModule", ["resetFilter", "filters"]),
    ...mapState("cityModule", ["selectedCity"]),
  },
  watch: {
    filters: {
      handler() {
        if (!this.resetFilter) {
          this.selectedItem = this.items[0];
        }
      },
    },
    selectedCity: {
      handler() {
        this.getFirstCity();
      },
    },
  },
  mounted() {
    this.getFirstCity();
  },
  methods: {
    ...mapActions("searchModule", {
      addFilter: actionTypes.removeFilter,
    }),
    selectItem(item) {
      this.selectedItem = item;
      this.isShowSelect = false;
      this.$emit("input", this.selectedItem);
    },
    toggleSelect() {
      this.isShowSelect = !this.isShowSelect;
    },
    getFilterSelect(item) {
      if (item === this.items[0]) {
        return this.$t(item.attributes[this.field]);
      } else {
        return item.attributes[this.field];
      }
    },
    getFirstCity() {
      if (this.title === "city") {
        console.log(
          "lol",
          this.items.find((e) => e.id === this.selectedCity.id)
        );
        this.selectedItem = this.items.find(
          (e) => e.id === this.selectedCity.id
        );
        return;
      }
      this.selectedItem = this.items[0];
    },
  },
};
</script>

<style lang="scss" scoped></style>
